import React, { lazy, Suspense } from "react";
import { BrowserRouter, matchPath } from "react-router-dom";
import "./App.css";
import { useUser } from "./contexts/UserContext";
import ClockPadTheme from "./theme/ClockPadTheme";
import { BCR, AlertProvider, AlertsFromProvider } from "best-common-react";
import { ThemeProvider } from "styled-components";
import "best-common-react/lib/styles/bcr.css";
import { RouteConstants } from "./constants/RouteConstants";

const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

const App: React.FC = () => {
  const { loggedIn } = useUser();

  const isStacked = new URLSearchParams(window.location.search).get("stacked") !== "false";
  const location = window.location.pathname;
  const clockMatch = matchPath(location, {
    path: RouteConstants.CLOCK,
    exact: true,
  });

  const containerStyle = {
    height: "100vh",
    ...(!isStacked && clockMatch ? { alignItems: "center", justifyContent: "center" } : {}),
  };

  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <ThemeProvider theme={ClockPadTheme}>
        <BCR theme={ClockPadTheme} containerStyle={containerStyle}>
          <AlertProvider>
            <AlertsFromProvider />
            <BrowserRouter>{loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}</BrowserRouter>
          </AlertProvider>
        </BCR>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
